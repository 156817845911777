//import React, { Component } from 'react';
//import { Route, Routes } from 'react-router-dom';
//import AppRoutes from './AppRoutes';
//import { Layout } from './components/Layout';
//import UpgradeProposal from './components/UpgradeProposal'; // Importaci�n sin llaves
//import './custom.css';

//export default class App extends Component {
//    static displayName = App.name;

//    render() {
//        return (
//            <Layout>
//                <Routes>
//                    {AppRoutes.map((route, index) => {
//                        const { element, ...rest } = route;
//                        return <Route key={index} {...rest} element={element} />;
//                    })}
//                    <Route path="/upgrade-proposal" element={<UpgradeProposal />} />
//                </Routes>
//            </Layout>
//        );
//    }
//}

import React from 'react';
import UpgradeProposal from './components/UpgradeProposal';

function App() {
    return (
        <div className="App">
            <UpgradeProposal />
        </div>
    );
}

export default App;