import React from 'react';

const RejectionConfirmationScreen = () => {
    return (
        <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: '#f8f9fa',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '20px',
            zIndex: 1000,
            fontFamily: 'Open Sans, Arial, sans-serif',
        }}>
            <div style={{
                backgroundColor: 'white',
                borderRadius: '10px',
                padding: '30px',
                maxWidth: '90%',
                width: '100%',
                maxWidth: '400px',
                textAlign: 'center',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            }}>
                <div style={{
                    width: '80px',
                    height: '80px',
                    borderRadius: '50%',
                    backgroundColor: '#F2575F',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: '0 auto 20px',
                }}>
                    <svg width="40" height="40" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18 6L6 18M6 6L18 18" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
                <h2 style={{
                    fontSize: '24px',
                    marginBottom: '15px',
                    color: '#F2575F',
                    fontFamily: 'Raleway, Arial, sans-serif',
                    fontWeight: '800'
                }}>Propuesta rechazada</h2>
                <p style={{ fontSize: '16px', marginBottom: '20px', color: '#333' }}>
                    Has decidido no aceptar el upgrade de tus entradas.
                </p>
                <div style={{
                    backgroundColor: '#FFF3CD',
                    borderRadius: '8px',
                    padding: '15px',
                    marginBottom: '20px',
                }}>
                    <h3 style={{
                        fontSize: '18px',
                        fontWeight: 'bold',
                        color: '#856404',
                        marginBottom: '10px',
                        fontFamily: 'Raleway, Arial, sans-serif'
                    }}>
                        ¿Qué sigue?
                    </h3>
                    <p style={{ fontSize: '14px', color: '#856404', lineHeight: '1.5' }}>
                        Tus entradas originales siguen siendo válidas. No es necesario realizar ninguna acción adicional.
                    </p>
                </div>
                <p style={{ fontSize: '16px', color: '#4D5A69', fontWeight: 'bold' }}>
                    Gracias por tu consideración.
                </p>
            </div>
        </div>
    );
};

export default RejectionConfirmationScreen;