import React, { useState } from 'react';

const RejectionReasonModal = ({ isOpen, onClose, onAccept, onReject, eventDetails, reasons }) => {
    const [selectedReason, setSelectedReason] = useState('');
    const [otherReason, setOtherReason] = useState('');
    const [error, setError] = useState('');

    if (!isOpen) return null;

    const handleReject = () => {
        if (!selectedReason) {
            setError('Por favor, selecciona un motivo para el rechazo.');
            return;
        }
        const selectedReasonObj = reasons.find(reason => reason.id === parseInt(selectedReason));
        if (selectedReasonObj.name === 'Otro' && !otherReason.trim()) {
            setError('Por favor, especifica el otro motivo.');
            return;
        }
        onReject({
            id: selectedReasonObj.id,
            reason: selectedReasonObj.name === 'Otro' ? otherReason : selectedReasonObj.name
        });
    };

    return (
        <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1000,
        }}>
            <div style={{
                backgroundColor: 'white',
                borderRadius: '10px',
                padding: '20px',
                width: '100%',
                maxWidth: '500px',
                maxHeight: '90vh',
                overflow: 'auto',
                boxSizing: 'border-box',
            }}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '15px'
                }}>
                    <h2 style={{
                        fontSize: '20px',
                        fontWeight: 'bold',
                        color: '#1CA2FF',
                        margin: 0
                    }}>¿Estás seguro de rechazar esta oportunidad única?</h2>
                    <button onClick={onClose} style={{
                        background: 'none',
                        border: 'none',
                        fontSize: '24px',
                        cursor: 'pointer',
                        color: '#6c757d'
                    }}>×</button>
                </div>

                <div style={{
                    backgroundColor: '#FFF9C4',
                    borderRadius: '8px',
                    padding: '10px',
                    marginBottom: '15px'
                }}>
                    <p style={{ fontWeight: 'bold', margin: '0 0 5px 0', color: '#FF6F00' }}>¡Última oportunidad!</p>
                    <p style={{ fontSize: '14px', margin: 0, color: '#FF6F00' }}>
                        Si rechazas, no podrás volver a acceder a esta propuesta exclusiva. Recuerda que EntradaFan cubre todos los costos adicionales.
                    </p>
                </div>

                <div style={{
                    backgroundColor: '#E3F2FD',
                    borderRadius: '8px',
                    padding: '10px',
                    marginBottom: '15px'
                }}>
                    <h3 style={{
                        fontSize: '16px',
                        fontWeight: 'bold',
                        color: '#1565C0',
                        marginBottom: '5px'
                    }}>Recuerda tu propuesta exclusiva:</h3>
                    <p style={{ color: '#1565C0', margin: 0, fontSize: '14px' }}>{eventDetails}</p>
                </div>

                <div style={{ marginBottom: '15px' }}>
                    <h3 style={{
                        fontSize: '16px',
                        fontWeight: 'bold',
                        color: '#2E7D32',
                        marginBottom: '10px'
                    }}>Si aún deseas rechazar, indica el motivo:</h3>
                    {reasons.map((reason) => (
                        <div key={reason.id} style={{ marginBottom: '10px' }}>
                            <input
                                type="radio"
                                id={`reason-${reason.id}`}
                                name="rejectionReason"
                                value={reason.id}
                                checked={selectedReason === reason.id.toString()}
                                onChange={(e) => {
                                    setSelectedReason(e.target.value);
                                    setError('');
                                }}
                                style={{ marginRight: '10px' }}
                            />
                            <label htmlFor={`reason-${reason.id}`} style={{ fontSize: '14px' }}>{reason.name}</label>
                        </div>
                    ))}
                    {selectedReason && reasons.find(r => r.id === parseInt(selectedReason))?.name === 'Otro' && (
                        <textarea
                            value={otherReason}
                            onChange={(e) => {
                                setOtherReason(e.target.value);
                                setError('');
                            }}
                            placeholder="Por favor, especifica el motivo"
                            style={{
                                width: '100%',
                                padding: '10px',
                                marginTop: '10px',
                                borderRadius: '5px',
                                border: '1px solid #ccc',
                            }}
                        />
                    )}
                </div>

                {error && (
                    <p style={{ color: 'red', marginBottom: '15px' }}>{error}</p>
                )}

                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    <button onClick={onAccept} style={{
                        backgroundColor: '#03CCB8',
                        color: 'white',
                        padding: '12px',
                        border: 'none',
                        borderRadius: '25px',
                        fontSize: '16px',
                        fontWeight: 'bold',
                        cursor: 'pointer',
                    }}>
                        ¡Cambié de opinión! Quiero aceptar la propuesta
                    </button>
                    <button onClick={handleReject} style={{
                        backgroundColor: '#FF0000',
                        color: 'white',
                        padding: '12px',
                        border: 'none',
                        borderRadius: '25px',
                        fontSize: '14px',
                        fontWeight: 'bold',
                        cursor: 'pointer',
                    }}>
                        Confirmar rechazo (no recomendado)
                    </button>
                </div>
            </div>
        </div>
    );
};

export default RejectionReasonModal;