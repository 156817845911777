import React, { useState, useEffect } from 'react';

const ModalProposal = ({ isOpen, onClose, onAccept, onShowRejectionModal, eventDetails, timeLeft }) => {
    const formatTime = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
    };

    if (!isOpen) return null;

    return (
        <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1000,
            fontFamily: 'Raleway, Arial, sans-serif',
        }}>
            <div style={{
                backgroundColor: 'white',
                borderRadius: '10px',
                padding: '20px',
                width: '100%',
                maxWidth: '500px',
                maxHeight: '90vh',
                overflow: 'auto',
                boxSizing: 'border-box',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            }}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '15px'
                }}>
                    <h2 style={{
                        fontSize: '28px',
                        fontWeight: '800',
                        color: '#1CA2FF',
                        margin: 0,
                        textTransform: 'uppercase',
                    }}>Propuesta Exclusiva</h2>
                    <button onClick={onClose} style={{
                        background: 'none',
                        border: 'none',
                        fontSize: '24px',
                        cursor: 'pointer',
                        color: '#6c757d'
                    }}>×</button>
                </div>

                <div style={{
                    backgroundColor: '#FFD700',
                    borderRadius: '5px',
                    padding: '10px',
                    marginBottom: '15px',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    fontSize: '16px',
                }}>
                    ¡ATENCIÓN! Esta es una oportunidad única. Por favor, revisa cuidadosamente los detalles.
                </div>

                <div style={{
                    backgroundColor: '#E3F2FD',
                    borderRadius: '8px',
                    padding: '15px',
                    marginBottom: '15px'
                }}>
                    <h3 style={{
                        fontSize: '22px',
                        fontWeight: 'bold',
                        color: '#1CA2FF',
                        marginBottom: '10px'
                    }}>Tu Nueva Experiencia Mejorada:</h3>
                    <p style={{ color: '#1565C0', margin: 0, fontSize: '16px' }}>{eventDetails}</p>
                </div>

                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: '15px',
                    fontSize: '20px',
                    fontWeight: 'bold',
                    color: '#F2575F',
                }}>
                    Oferta expira en: {formatTime(timeLeft)}
                </div>

                <div style={{
                    backgroundColor: '#E8F5E9',
                    borderRadius: '8px',
                    padding: '15px',
                    marginBottom: '15px',
                    textAlign: 'center'
                }}>
                    <p style={{
                        color: '#2E7D32',
                        fontWeight: 'bold',
                        fontSize: '18px',
                        margin: '0 0 10px 0'
                    }}>¡Oportunidad única!</p>
                    <p style={{ color: '#2E7D32', fontSize: '16px', margin: 0 }}>
                        Mejora tu experiencia sin costo adicional. ¡No dejes pasar esta propuesta exclusiva!
                    </p>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                    <button onClick={onAccept} style={{
                        backgroundColor: '#03CCB8',
                        color: 'white',
                        padding: '15px 30px',
                        border: 'none',
                        borderRadius: '50px',
                        fontSize: '20px',
                        fontWeight: 'bold',
                        cursor: 'pointer',
                        textTransform: 'uppercase',
                        transition: 'all 0.3s ease',
                        boxShadow: '0 4px 6px rgba(3, 204, 184, 0.3)',
                    }}>
                        ¡SÍ, QUIERO ESTA PROPUESTA AHORA MISMO!
                    </button>
                    <button onClick={onShowRejectionModal} style={{
                        backgroundColor: 'transparent',
                        color: '#6c757d',
                        border: 'none',
                        fontSize: '14px',
                        fontWeight: 'bold',
                        cursor: 'pointer',
                        textDecoration: 'underline',
                    }}>
                        Prefiero mantener mi entrada actual (no recomendado)
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ModalProposal;